import styled from "styled-components";
import { rem, standardFocusState } from "@mc/components/core/styles";
import { black, highlight, pureBlack, tan, white } from "@mc/components/core/colours.styles";
import { HEADER_HEIGHT } from "../header.styles";
import { fontSize14, fontWeightMedium } from "@mc/components/core/typography.styles";

type NavigationContainerProps = {
  isOpen: boolean;
  $withBorder?: boolean;
};

export const Overlay = styled.div<NavigationContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  top: ${rem(HEADER_HEIGHT)};
  background: ${pureBlack};
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  z-index: 999;
`;

export const MenuButton = styled.button``;

export const Navigation = styled.div``;

export const MenuContainer = styled.div<NavigationContainerProps>`
  ${Navigation} {
    position: fixed;
    top: ${rem(HEADER_HEIGHT)};
    right: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
    width: ${rem(280)};
    max-height: calc(100vh - ${rem(HEADER_HEIGHT)});
    background-color: ${tan};
    padding: ${rem(16)} ${rem(16)} ${rem(16)} 0;
    overflow-x: hidden;
    z-index: 1000;

    &::before {
      content: "";
      position: fixed;
      top: 55px;
      left: calc(100% - 45px);
      transform: translateX(-50%);
      border-width: ${rem(8)};
      border-style: solid;
      border-color: transparent transparent ${tan} transparent;
      display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    }

    @media (max-width: ${rem(768)}) {
      width: 75vw;
    }
    overflow-y: auto;
  }
  ${MenuButton} {
    background-color: ${white};
    border: ${({ $withBorder = true }) => ($withBorder ? `1px solid ${tan}` : `none`)};
    border-radius: ${rem(40)};
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${black};
    padding: 0;
    height: ${rem(40)};
    width: ${rem(40)};
    line-height: ${rem(40)};

    &:hover {
      background-color: ${tan};
      cursor: pointer;
    }

    &[aria-expanded="true"] {
      background-color: ${tan};
    }

    ${standardFocusState}
  }
`;

export const MenuList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const MenuItem = styled.li<{ isDisabled?: boolean }>`
  width: 98%;
  display: flex;
  flex-direction: column;
  gap: ${rem(8)};
  position: relative;
  opacity: ${({ isDisabled = false }) => (isDisabled ? 0.5 : 1)};
  pointer-events: ${({ isDisabled = false }) => (isDisabled ? "none" : "auto")};
`;

export const MenuItemHeader = styled.div<{ hasSubmenu?: boolean }>`
  padding: ${rem(12)} ${rem(16)};
  border-radius: ${rem(150)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: ${rem(8)} ${rem(24)} ${rem(8)} ${rem(12)};
  &:hover {
    cursor: pointer;
    background-color: ${({ hasSubmenu }) => (hasSubmenu ? "inherit" : highlight)};
  }
`;

export const MenuLabel = styled.span`
  color: ${black};
  font-size: ${fontSize14};
  font-weight: ${fontWeightMedium};
  margin-left: ${rem(16)};
  flex-grow: 1;
`;

export const MenuLink = styled.a<{ $isSubmenu?: boolean }>`
  color: ${black};
  font-size: ${fontSize14};
  font-weight: ${fontWeightMedium};
  margin-left: ${rem(16)};
  text-decoration: ${({ $isSubmenu }) => ($isSubmenu ? "underline" : "none")};
  flex-grow: 1;
  &:hover {
    cursor: pointer;
    color: ${({ $isSubmenu }) => ($isSubmenu ? highlight : black)};
  }
`;

export const ArrowIcon = styled.span`
  display: flex;
  align-items: center;
  padding-left: ${rem(8)};
`;

export const SubMenu = styled.ul<{ isOpen: boolean }>`
  list-style: none;
  margin: 0;
  right: 0;
  padding: ${rem(8)} 0;
  background-color: ${tan};
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  padding-left: ${rem(40)};
`;

export const SubMenuItem = styled.li`
  padding: ${rem(8)} ${rem(16)};
  white-space: nowrap;
`;
