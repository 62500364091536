import styled from "styled-components";
import { Link } from "react-router-dom";

import { lightGrey, red, white } from "@mc/components/core/colours.styles";
import { rem, standardFocusState } from "@mc/components/core/styles";
import { LayoutColour } from "../layout.constants";
import { Flex } from "@mc/components/grid/grid.styles";

export const HEADER_HEIGHT = 70;

type HeaderProps = {
  hasScrolled?: boolean;
  isNormalFlowingPage?: boolean;
  backgroundColor?: LayoutColour;
};
export const HeaderStyled = styled.header<HeaderProps>`
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : white)};
  height: ${rem(HEADER_HEIGHT)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 ${rem(24)};
  border-bottom: 1px solid ${lightGrey};

  ${({ isNormalFlowingPage }) =>
    !isNormalFlowingPage &&
    ` 
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 4;
  `}

  ${({ hasScrolled }) =>
    hasScrolled &&
    `
    box-shadow: 0px 8px 15px rgba(57,16,133,0.08);
  `}
`;

export const HeaderLogoStyled = styled.img`
  width: ${rem(50)};
`;

export const HeaderLogoLinkStyled = styled(Link)`
  display: block;
  ${standardFocusState};
`;

export const HeaderMenuContainerStyled = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: ${rem(24)};
  top: 0;
  bottom: 0;
  z-index: 3;
`;

export const TopBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: ${red};
  color: ${white};
  padding: ${rem(8)} ${rem(16)};
`;

export const LogoWrapper = styled(Flex)`
  svg {
    cursor: pointer;
  }
`;

export const HeaderOwnLogoImgLinkStyled = styled(Link)`
  display: flex;
  align-items: center;
`;

export const OwnLogoImg = styled.img`
  width: ${rem(120)};
`;
