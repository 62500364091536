import { useState, useCallback } from "react";
import * as Styled from "./navigation-menu-secondary.styles";
import { Menu } from "@headlessui/react";
import { LuChevronDown, LuChevronUp } from "react-icons/lu";
import { HeaderLogoLinkStyled, LogoWrapper } from "../header.styles";
import { MdClose } from "react-icons/md";
import Icon from "@mc/components/icons/Icon";
import { HOME } from "routing/routes";

export const useNavigationState = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);
  const toggle = useCallback(() => setIsOpen((prev) => !prev), []);
  return { isOpen, open, close, toggle };
};

export interface MenuItemSecondaryType {
  label: string;
  value?: string;
  icon?: React.ReactNode;
  isDisabled?: boolean;
  submenu?: MenuItemSecondaryType[];
}

type Props = {
  show: boolean;
  onClick: () => void;
  $withBorder?: boolean;
  options: MenuItemSecondaryType[];
  onSelect?: (option: MenuItemSecondaryType) => void;
};

const NavigationMenuSecondary = ({ show = false, $withBorder, options, onSelect, onClick }: Props) => {
  const [openSubmenus, setOpenSubmenus] = useState<{ [key: number]: boolean }>({});

  const toggleSubmenu = (e: React.MouseEvent, index: number) => {
    e.stopPropagation();
    setOpenSubmenus((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const handleItemClick = (e: React.MouseEvent, item: MenuItemSecondaryType) => {
    if (item.isDisabled) {
      e.preventDefault();
      return;
    }
    if (onSelect) {
      e.preventDefault();
      onSelect(item);
      close();
    }
  };

  const handleSubmenuClick = (
    e: React.MouseEvent,
    parentItem: MenuItemSecondaryType,
    subItem: MenuItemSecondaryType
  ) => {
    if (subItem.isDisabled) {
      e.preventDefault();
      return;
    }
    if (onSelect) {
      e.preventDefault();
      onSelect({ ...parentItem, label: subItem.label, value: subItem.value, isDisabled: subItem.isDisabled });
      close();
    }
  };

  return (
    <Styled.MenuContainer isOpen={show} $withBorder={$withBorder}>
      <Styled.Overlay
        isOpen={show}
        onClick={() => {
          onClick();
        }}
      />
      <Menu as="div">
        <Styled.Navigation>
          <LogoWrapper alignItems="center">
            <MdClose size={32} onClick={onClick} />
            <HeaderLogoLinkStyled to={HOME}>
              <Icon id="milkChocolateLogo" aria-label="Milk Chocolate Property Logo" width={150} />
            </HeaderLogoLinkStyled>
          </LogoWrapper>
          <Styled.MenuList>
            {options.map((item, index) => {
              const hasSubmenu = Array.isArray(item.submenu) && item.submenu.length > 0;
              return (
                <Styled.MenuItem key={index} isDisabled={item?.isDisabled}>
                  <Styled.MenuItemHeader
                    onClick={(e) => {
                      if (hasSubmenu) {
                        toggleSubmenu(e, index);
                      } else {
                        handleItemClick(e, item);
                      }
                    }}
                    role="button"
                    aria-expanded={hasSubmenu ? openSubmenus[index] || false : undefined}
                  >
                    {item.icon}
                    {hasSubmenu ? (
                      <Styled.MenuLabel>{item.label}</Styled.MenuLabel>
                    ) : (
                      <Styled.MenuLink>{item.label}</Styled.MenuLink>
                    )}
                    {hasSubmenu && (
                      <Styled.ArrowIcon>
                        {openSubmenus[index] ? <LuChevronUp size={24} /> : <LuChevronDown size={24} />}
                      </Styled.ArrowIcon>
                    )}
                  </Styled.MenuItemHeader>
                  {hasSubmenu && (
                    <Styled.SubMenu isOpen={openSubmenus[index] || false}>
                      {item?.submenu?.map((subItem, subIndex) => (
                        <Styled.SubMenuItem key={subIndex}>
                          <Styled.MenuLink $isSubmenu onClick={(e) => handleSubmenuClick(e, item, subItem)}>
                            {subItem.label}
                          </Styled.MenuLink>
                        </Styled.SubMenuItem>
                      ))}
                    </Styled.SubMenu>
                  )}
                </Styled.MenuItem>
              );
            })}
          </Styled.MenuList>
        </Styled.Navigation>
      </Menu>
    </Styled.MenuContainer>
  );
};

export default NavigationMenuSecondary;
