import { Route, Routes } from "react-router-dom";
import { REFER_A_FRIEND_THANKS } from "routing/routes";
import Layout from "shared/layout/layout";
import { lazy } from "react";

const New = lazy(() => import("./new"));
const Thanks = lazy(() => import("./thanks"));

const ReferAFriendRoutes = () => {
  return (
    <Layout>
      <Routes>
        <Route path={"/"} element={<New />} />
        <Route path={REFER_A_FRIEND_THANKS} element={<Thanks />} />
      </Routes>
    </Layout>
  );
};

export default ReferAFriendRoutes;
