import { generatePath, useLocation, useNavigate } from "react-router-dom";
import {
  ACCOUNT_PAYMENTS,
  ACCOUNT_PROFILE,
  ACTIVE_PORTFOLIO,
  GAME_PLAN,
  HOME,
  LOGIN,
  REFER_A_FRIEND,
} from "routing/routes";
import { ButtonIcon } from "@mc/components/buttons";
import { useHeaderScroll } from "./header.hooks";
import {
  HeaderStyled,
  HeaderLogoLinkStyled,
  HeaderMenuContainerStyled,
  TopBar,
  LogoWrapper,
  OwnLogoImg,
  HeaderOwnLogoImgLinkStyled,
} from "./header.styles";
import { LayoutColour } from "../layout.constants";
import Icon from "@mc/components/icons/Icon";
import NavigationMenu, { MenuItemType, useNavigationState } from "./navigation-menu/navigation-menu";
import { useUser } from "user/user.hooks";
import * as Styled from "../navigation/navigation.styles";
import { clearUserStorage } from "user/user.storage";
import { useApolloClient } from "@apollo/client";
import { AnchorStyled } from "@mc/components/core/typography.styles";
import { white } from "@mc/components/core/colours.styles";
import { useModalState } from "@mc/components/modal/modal";
import AskAQuestionModal from "./ask-a-question-modal/ask-a-question-modal";
import NavigationMenuSecondary, { MenuItemSecondaryType } from "./navigation-menu-secondary/navigation-menu-secondary";
import OwnLogo from "../../../images/own_logo.png";

type Props = {
  isNormalFlowingPage?: boolean;
  backgroundColor?: LayoutColour;
  goBackTo?: string;
  goBack?: boolean;
};

const Header = ({ isNormalFlowingPage, backgroundColor, goBackTo, goBack }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { hasScrolled } = useHeaderScroll();
  const { user } = useUser();
  const client = useApolloClient();
  const { isVisible, open, close } = useModalState();
  const { isOpen, toggle } = useNavigationState();
  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const showOwnLogo = location.pathname.startsWith(ACTIVE_PORTFOLIO);
  const menuItems: MenuItemType[] = [
    {
      label: "My Profile",
      value: "MY_PROFILE",
      icon: <Icon id="profile" height={24} width={24} />,
    },
    {
      label: "Refer a friend",
      value: "REFER_A_FRIEND",
      icon: <Icon id="thumb-up" height={24} width={24} />,
    },
    {
      label: "Ask a question",
      value: "ASK_A_QUESTION",
      icon: <Icon id="ask-question" height={24} width={24} />,
    },
    {
      label: "Sign out",
      value: "SIGN_OUT",
      icon: <Icon id="sign-out" height={24} width={24} />,
    },
  ];

  const menuItemSecondary: MenuItemSecondaryType[] = [
    {
      label: "Home",
      value: "HOME",
      icon: <Icon id="home" height={24} width={24} />,
    },
    {
      label: "Own™ COMING SOON!",
      value: "OWN",
      icon: <Icon id="own" height={24} width={24} />,
      isDisabled: true,
    },
    {
      label: "Game Plan™",
      value: "GAME_PLAN",
      icon: <Icon id="game-plan" height={24} width={24} />,
    },

    {
      label: "Guides",
      value: "GUIDES",
      icon: <Icon id="guide" height={24} width={24} />,
      submenu: [
        { label: "Investment property", value: "INVESTMENT_PROPERTY" },
        { label: "Family Home", value: "FAMILY_HOME" },
        { label: "Construction", value: "CONSTRUCTION" },
        { label: "Property Management", value: "PROPERTY_MANAGEMENT" },
      ],
    },
    {
      label: "Services",
      value: "SERVICES",
      icon: <Icon id="thumb-up" height={24} width={24} />,
      submenu: [
        { label: "Buy (investment)", value: "BUY_INVESTMENT" },
        { label: "Buy (family home)", value: "BUY_FAMILY_HOME" },
        { label: "Upgrade", value: "UPGRADE" },
        { label: "Build", value: "BUILD" },
        { label: "Management", value: "MANAGEMENT" },
      ],
    },
  ];

  const onSelect: (option: MenuItemType) => void = async (opt) => {
    switch (opt.value) {
      case "HOME":
        navigate(generatePath(HOME));
        break;

      case "MY_PROFILE":
        navigate(generatePath(ACCOUNT_PROFILE));
        break;

      case "GAME_PLAN":
        navigate(GAME_PLAN, { replace: true });
        break;

      case "INVESTMENT_PROPERTY":
        navigate("/guide/investment-purchasing");
        break;

      case "FAMILY_HOME":
        navigate("/guide/family-home-purchasing");
        break;

      case "CONSTRUCTION":
        navigate("/guide/construction");
        break;

      case "PROPERTY_MANAGEMENT":
        navigate("/guide/property-management");
        break;

      case "BUY_INVESTMENT":
        openInNewTab("https://storage.googleapis.com/mc-public/documents/milk-chocolate-buy-investment.pdf");
        break;

      case "BUY_FAMILY_HOME":
        openInNewTab("https://storage.googleapis.com/mc-public/documents/milk-chocolate-buy-family-home.pdf");
        break;

      case "UPGRADE":
        openInNewTab("https://storage.googleapis.com/mc-public/documents/milk-chocolate-upgrade.pdf");
        break;

      case "BUILD":
        openInNewTab("https://storage.googleapis.com/mc-public/documents/milk-chocolate-build.pdf");
        break;

      case "MANAGEMENT":
        openInNewTab("https://storage.googleapis.com/mc-public/documents/milk-chocolate-pm.pdf");
        break;

      case "REFER_A_FRIEND":
        navigate(REFER_A_FRIEND);
        break;

      case "ASK_A_QUESTION":
        open();
        break;

      case "SIGN_OUT":
        clearUserStorage();
        client.clearStore();
        navigate(LOGIN, { replace: true });
        break;
    }
  };

  const foundFailedPaymentAttempts = user?.jobs?.find((job) => job.failedPaymentAttempts)?.failedPaymentAttempts;

  return (
    <>
      {foundFailedPaymentAttempts &&
        (foundFailedPaymentAttempts >= 3 ? (
          <TopBar>
            Please{" "}
            <AnchorStyled to={ACCOUNT_PAYMENTS} style={{ color: white }}>
              update your payment details
            </AnchorStyled>{" "}
            to avoid losing access.
          </TopBar>
        ) : foundFailedPaymentAttempts > 0 ? (
          <TopBar>
            Oops, it looks like your latest payment didn't go through! Please check your financial institution or{" "}
            <AnchorStyled to={ACCOUNT_PAYMENTS} style={{ color: white }}>
              update your payment details
            </AnchorStyled>
            .
          </TopBar>
        ) : null)}
      <HeaderStyled
        hasScrolled={hasScrolled}
        isNormalFlowingPage={isNormalFlowingPage}
        backgroundColor={backgroundColor}
      >
        {goBackTo || goBack ? (
          <HeaderMenuContainerStyled>
            <ButtonIcon
              type="button"
              noBorder
              iconId="close"
              onClick={() => (goBackTo ? navigate(goBackTo, { replace: true }) : goBack ? navigate(-1) : false)}
            />
          </HeaderMenuContainerStyled>
        ) : (
          <>
            <LogoWrapper alignItems="center">
              {user && <Icon id="hamburger" aria-label="Milk Chocolate menu" width={30} onClick={toggle} />}

              <HeaderLogoLinkStyled
                as={user && showOwnLogo ? HeaderOwnLogoImgLinkStyled : HeaderLogoLinkStyled}
                to={HOME}
              >
                {user && showOwnLogo ? (
                  <OwnLogoImg src={OwnLogo} alt="Milk Chocolate Own Logo" />
                ) : (
                  <Icon id="milkChocolateLogo" aria-label="Milk Chocolate Property Logo" width={150} />
                )}
              </HeaderLogoLinkStyled>
            </LogoWrapper>
            {user && (
              <NavigationMenu
                $withBorder={false}
                options={menuItems}
                onSelect={onSelect}
                icon={<Styled.MenuAvatar firstName={user?.firstName} lastName={user?.lastName} />}
              />
            )}
          </>
        )}
        {isVisible && <AskAQuestionModal close={close} />}
      </HeaderStyled>
      {user && (
        <NavigationMenuSecondary
          show={isOpen}
          $withBorder={false}
          options={menuItemSecondary}
          onSelect={onSelect}
          onClick={toggle}
        />
      )}
    </>
  );
};

export default Header;
