import styled from "styled-components";
import { rem, mediaUp } from "../core/styles";
import { red, green, white, highlight, black, darkGrey, softGrey } from "../core/colours.styles";
import { AnchorStyled, fontSize16, lineHeight22 } from "../core/typography.styles";
import { ButtonIcon } from "../buttons";
import { NotificationPosition } from "./notification.context";
import { SpacingValue } from "../core/spacing.styles";

type Props = {
  displayNotification?: boolean;
  neutral?: boolean;
  warning?: boolean;
  error?: boolean;
  position?: NotificationPosition;
  hideable?: boolean;
  fullWidth?: boolean;
  isPlaceholder?: boolean;
  marginBottom?: SpacingValue;
  textAlign?: "left" | "center" | "right";
};

export const NotificationStyled = styled.div<Props>`
  background-color: ${green};
  border-radius: ${rem(4)};
  box-sizing: border-box;
  color: ${white};
  display: none;
  font-size: ${fontSize16};
  line-height: ${lineHeight22};
  max-width: ${rem(456)};
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  padding: ${rem(12)} ${rem(16)};

  width: calc(100% - ${rem(16)});
  z-index: 100000;

  ${mediaUp.sm`
    width: auto;
  `}

  ${(props) =>
    props.hideable &&
    `
    padding-right: ${rem(48)};
  `}

  ${(props) =>
    props.warning &&
    `
    color: ${white};
    background-color: ${highlight};
  `}

  ${(props) =>
    props.error &&
    `
    color: ${white};
    background-color: ${red};

    ${AnchorStyled} {
      color: ${white};
    }
  `}

  ${({ displayNotification, position }) =>
    displayNotification &&
    `
    display: block;
    animation: ${
      position === NotificationPosition.Top ? "slideDownFromTop" : "slideUp"
    } 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  `}

  @keyframes slideUp {
    0% {
      transform: translate(-50%, 100%);
    }
    100% {
      transform: translate(-50%, -100%);
    }
  }

  ${({ displayNotification, position }) =>
    displayNotification === false &&
    `
    display: block;
    animation: ${
      position === NotificationPosition.Top ? "slideUpFromTop" : "slideDown"
    } 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  `}

  @keyframes slideDown {
    0% {
      transform: translate(-50%, -100%);
    }
    100% {
      transform: translate(-50%, 100%);
    }
  }

  @keyframes slideDownFromTop {
    0% {
      transform: translate(-50%, -100%);
    }
    100% {
      transform: translate(-50%, ${16}px);
    }
  }
  @keyframes slideUpFromTop {
    0% {
      transform: translate(-50%, 0%);
    }
    100% {
      transform: translate(-50%, -100%);
    }
  }
`;

export const NotificationClose = styled(ButtonIcon).attrs({
  noTooltip: true,
  noBorder: true,
})`
  position: absolute;
  right: ${rem(8)};
  top: ${rem(8)};
  height: ${rem(32)};
  width: ${rem(32)};

  &:hover {
    background-color: rgba(0, 0, 0, 0.25);
  }
  svg {
    fill: ${white};
  }
`;

export const NotificationInlineStyled = styled.div<Props>`
  background-color: ${green};
  border-radius: 4px;
  box-sizing: border-box;
  color: ${white};
  font-size: ${fontSize16};
  line-height: ${lineHeight22};
  max-width: ${(props) => (props.fullWidth ? `100%` : rem(456))};
  margin-bottom: ${({ marginBottom = 24 }) => rem(marginBottom)};
  padding: ${rem(16)};
  text-align: ${(props) => props.textAlign || "left"};

  ${mediaUp.sm`
    width: 100%;
  `}

  ${(props) =>
    props.neutral &&
    `
    color: ${black};
    background-color: ${softGrey};
  `}

  ${(props) =>
    props.warning &&
    `
    color: ${white};
    background-color: ${highlight};
  `}

  ${(props) =>
    props.error &&
    `
    color: ${white};
    background-color: ${red};

    ${AnchorStyled} {
      color: ${white};
    }
  `}

  ${(props) =>
    props.isPlaceholder &&
    `
    color: ${darkGrey};
    background-color: ${softGrey};
  `}
`;
