export const HOME = `/`;
export const LOGIN = `/login`;
export const CONTACT_US = `/contact`;
export const CORE_LOGIC_DISCLAIMER = `/core-logic-disclaimer`;
export const CHART_DISCLAIMER = `/chart-disclaimer`;

/**
 * JOIN
 */
export const JOIN = `/join`;
export const JOIN_ROUTE = `${JOIN}/*`;

export const JOIN_REGISTER_ROUTE = `register`;
export const JOIN_VERIFY_ROUTE = `verify`;
export const JOIN_SUCCESS_ROUTE = `success`;

export const JOIN_REGISTER = `${JOIN}/${JOIN_REGISTER_ROUTE}`;
export const JOIN_VERIFY = `${JOIN}/${JOIN_VERIFY_ROUTE}`;
export const JOIN_SUCCESS = `${JOIN}/${JOIN_SUCCESS_ROUTE}`;

/**
 * FORGOT PASSWORD
 */
export const FORGOT_PASSWORD = `/forgot-password`;
export const FORGOT_PASSWORD_ROUTE = `${FORGOT_PASSWORD}/*`;

export const FORGOT_PASSWORD_SUCCESS_ROUTE = `success`;
export const FORGOT_PASSWORD_RESET_ROUTE = `reset`;

export const FORGOT_PASSWORD_SUCCESS = `${FORGOT_PASSWORD}/${FORGOT_PASSWORD_SUCCESS_ROUTE}`;
export const FORGOT_PASSWORD_RESET = `${FORGOT_PASSWORD}/${FORGOT_PASSWORD_RESET_ROUTE}`;

/**
 * ACCOUNT
 */
export const ACCOUNT = `/account`;
export const ACCOUNT_ROUTE = `${ACCOUNT}/*`;
export const ACCOUNT_SETTINGS_ROUTE = `settings/*`;
export const ACCOUNT_WELCOME_ROUTE = `welcome`;
export const ACCOUNT_PROFILE_ROUTE = `profile`;
export const ACCOUNT_PROFESSIONAL_SERVICES_ROUTE = `professional-services`;
export const ACCOUNT_SECURITY_ROUTE = `security`;
export const ACCOUNT_PAYMENTS_ROUTE = `payments`;
export const ACCOUNT_DOCUMENTS_ROUTE = `documents`;
export const ACCOUNT_STANDARD_PURCHASE_TERMS_ROUTE = `standard-purchase-terms`;

export const ACCOUNT_SETTINGS = `${ACCOUNT}/settings`;
export const ACCOUNT_WELCOME = `${ACCOUNT}/${ACCOUNT_WELCOME_ROUTE}`;
export const ACCOUNT_PROFILE = `${ACCOUNT_SETTINGS}/${ACCOUNT_PROFILE_ROUTE}`;
export const ACCOUNT_PROFESSIONAL_SERVICES = `${ACCOUNT_SETTINGS}/${ACCOUNT_PROFESSIONAL_SERVICES_ROUTE}`;
export const ACCOUNT_SECURITY = `${ACCOUNT_SETTINGS}/${ACCOUNT_SECURITY_ROUTE}`;
export const ACCOUNT_PAYMENTS = `${ACCOUNT_SETTINGS}/${ACCOUNT_PAYMENTS_ROUTE}`;
export const ACCOUNT_DOCUMENTS = `${ACCOUNT_SETTINGS}/${ACCOUNT_DOCUMENTS_ROUTE}`;
export const ACCOUNT_STANDARD_PURCHASE_TERMS = `${ACCOUNT}/${ACCOUNT_STANDARD_PURCHASE_TERMS_ROUTE}`;

/**
 * GET IN TOUCH
 */
export const GET_IN_TOUCH_PAGE = "/get-in-touch";

/**
 * ONBOARDING
 */
export const ONBOARDING = "/onboarding";
export const ONBOARDING_ROUTE = `${ONBOARDING}/*`;

export const ONBOARDING_START_ROUTE = `start`;
export const ONBOARDING_PERSONAL_DETAILS_ROUTE = `personal-details`;
export const ONBOARDING_EXISTING_PROPERTIES_ROUTE = `existing-properties`;
export const ONBOARDING_INVESTMENT_GOALS_ROUTE = `investment-goals`;
export const ONBOARDING_PURCHASE_AGREEMENT_ROUTE = `purchase-agreement`;
export const ONBOARDING_HOME_PURCHASE_DETAILS_ROUTE = `home-purchase-details`;
export const ONBOARDING_FAMILY_HOME_PREFERENCES_ROUTE = `family-home-preferences`;
export const ONBOARDING_OTHER_REQUIREMENTS_ROUTE = `other-requirements`;

// INVESTMENT
export const INVESTMENT_ROUTE = `investment/*`;
export const INVESTMENT = `${ONBOARDING}/investment`;
export const ONBOARDING_INVESTMENT_START = `${INVESTMENT}/${ONBOARDING_START_ROUTE}`;
export const ONBOARDING_INVESTMENT_PERSONAL_DETAILS = `${INVESTMENT}/${ONBOARDING_PERSONAL_DETAILS_ROUTE}`;
export const ONBOARDING_INVESTMENT_EXISTING_PROPERTIES = `${INVESTMENT}/${ONBOARDING_EXISTING_PROPERTIES_ROUTE}`;
export const ONBOARDING_INVESTMENT_INVESTMENT_GOALS = `${INVESTMENT}/${ONBOARDING_INVESTMENT_GOALS_ROUTE}`;
export const ONBOARDING_INVESTMENT_PURCHASE_AGREEMENT = `${INVESTMENT}/${ONBOARDING_PURCHASE_AGREEMENT_ROUTE}`;
export const ONBOARDING_INVESTMENT_OTHER_REQUIREMENTS = `${INVESTMENT}/${ONBOARDING_OTHER_REQUIREMENTS_ROUTE}`;

// FAMILY HOME
export const FAMILY_ROUTE = `family/*`;
export const FAMILY = `${ONBOARDING}/family`;
export const ONBOARDING_FAMILY_START = `${FAMILY}/${ONBOARDING_START_ROUTE}`;
export const ONBOARDING_FAMILY_PERSONAL_DETAILS = `${FAMILY}/${ONBOARDING_PERSONAL_DETAILS_ROUTE}`;
export const ONBOARDING_FAMILY_EXISTING_PROPERTIES = `${FAMILY}/${ONBOARDING_EXISTING_PROPERTIES_ROUTE}`;
export const ONBOARDING_FAMILY_HOME_PURCHASE_DETAILS = `${FAMILY}/${ONBOARDING_HOME_PURCHASE_DETAILS_ROUTE}`;
export const ONBOARDING_FAMILY_PURCHASE_AGREEMENT = `${FAMILY}/${ONBOARDING_PURCHASE_AGREEMENT_ROUTE}`;
export const ONBOARDING_FAMILY_OTHER_REQUIREMENTS = `${FAMILY}/${ONBOARDING_OTHER_REQUIREMENTS_ROUTE}`;

// INVESTMENT FAMILY
export const INVESTMENT_FAMILY_ROUTE = `investment-family/*`;
export const INVESTMENT_FAMILY = `${ONBOARDING}/investment-family`;
export const ONBOARDING_INVESTMENT_FAMILY_START = `${INVESTMENT_FAMILY}/${ONBOARDING_START_ROUTE}`;
export const ONBOARDING_INVESTMENT_FAMILY_PERSONAL_DETAILS = `${INVESTMENT_FAMILY}/${ONBOARDING_PERSONAL_DETAILS_ROUTE}`;
export const ONBOARDING_INVESTMENT_FAMILY_EXISTING_PROPERTIES = `${INVESTMENT_FAMILY}/${ONBOARDING_EXISTING_PROPERTIES_ROUTE}`;
export const ONBOARDING_INVESTMENT_FAMILY_INVESTMENT_GOALS = `${INVESTMENT_FAMILY}/${ONBOARDING_INVESTMENT_GOALS_ROUTE}`;
export const ONBOARDING_INVESTMENT_FAMILY_HOME_PURCHASE_DETAILS = `${INVESTMENT_FAMILY}/${ONBOARDING_HOME_PURCHASE_DETAILS_ROUTE}`;
export const ONBOARDING_INVESTMENT_FAMILY_FAMILY_HOME_PREFERENCES = `${INVESTMENT_FAMILY}/${ONBOARDING_FAMILY_HOME_PREFERENCES_ROUTE}`;
export const ONBOARDING_INVESTMENT_FAMILY_PURCHASE_AGREEMENT = `${INVESTMENT_FAMILY}/${ONBOARDING_PURCHASE_AGREEMENT_ROUTE}`;
export const ONBOARDING_INVESTMENT_FAMILY_OTHER_REQUIREMENTS = `${INVESTMENT_FAMILY}/${ONBOARDING_OTHER_REQUIREMENTS_ROUTE}`;

// FAMILY INVESTMENT
export const FAMILY_INVESTMENT_ROUTE = `family-investment/*`;
export const FAMILY_INVESTMENT = `${ONBOARDING}/family-investment`;
export const ONBOARDING_FAMILY_INVESTMENT_START = `${FAMILY_INVESTMENT}/${ONBOARDING_START_ROUTE}`;
export const ONBOARDING_FAMILY_INVESTMENT_PERSONAL_DETAILS = `${FAMILY_INVESTMENT}/${ONBOARDING_PERSONAL_DETAILS_ROUTE}`;
export const ONBOARDING_FAMILY_INVESTMENT_EXISTING_PROPERTIES = `${FAMILY_INVESTMENT}/${ONBOARDING_EXISTING_PROPERTIES_ROUTE}`;
export const ONBOARDING_FAMILY_INVESTMENT_INVESTMENT_GOALS = `${FAMILY_INVESTMENT}/${ONBOARDING_INVESTMENT_GOALS_ROUTE}`;
export const ONBOARDING_FAMILY_INVESTMENT_HOME_PURCHASE_DETAILS = `${FAMILY_INVESTMENT}/${ONBOARDING_HOME_PURCHASE_DETAILS_ROUTE}`;
export const ONBOARDING_FAMILY_INVESTMENT_PURCHASE_AGREEMENT = `${FAMILY_INVESTMENT}/${ONBOARDING_PURCHASE_AGREEMENT_ROUTE}`;
export const ONBOARDING_FAMILY_INVESTMENT_OTHER_REQUIREMENTS = `${FAMILY_INVESTMENT}/${ONBOARDING_OTHER_REQUIREMENTS_ROUTE}`;

export const ONBOARDING_PAYMENT_ROUTE = `payment`;
export const ONBOARDING_PAYMENT = `${ONBOARDING}/${ONBOARDING_PAYMENT_ROUTE}`;

// GUIDE
export const ONBOARDING_GUIDE_ROUTE = `guide/*`;
export const ONBOARDING_GUIDE_INVESTMENT_PROPERTY_ROUTE = `buying-an-investment-property`;
export const ONBOARDING_GUIDE_FAMILY_HOME_ROUTE = `buying-a-family-home`;
export const ONBOARDING_GUIDE_GAME_PLANS_ROUTE = `game-plans`;
export const ONBOARDING_GUIDE_PROJECTS_ROUTE = `projects`;
export const ONBOARDING_GUIDE_PROPERTY_MANAGEMENT_ROUTE = `property-management`;

export const ONBOARDING_GUIDE = `${ONBOARDING}/guide`;
export const ONBOARDING_GUIDE_INVESTMENT_PROPERTY = `${ONBOARDING_GUIDE}/${ONBOARDING_GUIDE_INVESTMENT_PROPERTY_ROUTE}`;
export const ONBOARDING_GUIDE_FAMILY_HOME = `${ONBOARDING_GUIDE}/${ONBOARDING_GUIDE_FAMILY_HOME_ROUTE}`;
export const ONBOARDING_GUIDE_GAME_PLANS = `${ONBOARDING_GUIDE}/${ONBOARDING_GUIDE_GAME_PLANS_ROUTE}`;
export const ONBOARDING_GUIDE_PROJECTS = `${ONBOARDING_GUIDE}/${ONBOARDING_GUIDE_PROJECTS_ROUTE}`;
export const ONBOARDING_GUIDE_PROPERTY_MANAGEMENT = `${ONBOARDING_GUIDE}/${ONBOARDING_GUIDE_PROPERTY_MANAGEMENT_ROUTE}`;

/**
 * generate GUIDE
 */
export const GUIDE = `/guide/:type`;
export const GAME_PLAN_FAQS = `/game-plan-faqs`;

/**
 * GAME PLAN
 */
export const GAME_PLAN = `/game-plan`;
export const GAME_PLAN_ROUTE = `${GAME_PLAN}/*`;
export const GAME_PLAN_INTRO_ROUTE = `intro`;
export const GAME_PLAN_COMPARISON_ROUTE = `comparison`;
export const GAME_PLAN_OPTION_DETAILS_ROUTE = `option`;
export const GAME_PLAN_EXISTING_PROPERTY_ROUTE = `existing-property/:id`;
export const GAME_PLAN_CHOOSE_OPTION_ROUTE = `choose-option`;
export const GAME_PLAN_FEEDBACK_ROUTE = `feedback`;
export const GAME_PLAN_FEEDBACK_COMPLETE_ROUTE = `feedback-complete`;
export const GAME_PLAN_BREAKDOWN_ROUTE = `breakdown/:gamePlanOptionId`;

export const GAME_PLAN_INTRO = `${GAME_PLAN}/${GAME_PLAN_INTRO_ROUTE}`;
export const GAME_PLAN_COMPARISON = `${GAME_PLAN}/${GAME_PLAN_COMPARISON_ROUTE}`;
export const GAME_PLAN_OPTION_DETAILS = `${GAME_PLAN}/${GAME_PLAN_OPTION_DETAILS_ROUTE}`;
export const GAME_PLAN_EXISTING_PROPERTY = `${GAME_PLAN}/${GAME_PLAN_EXISTING_PROPERTY_ROUTE}`;
export const GAME_PLAN_CHOOSE_OPTION = `${GAME_PLAN}/${GAME_PLAN_CHOOSE_OPTION_ROUTE}`;
export const GAME_PLAN_FEEDBACK = `${GAME_PLAN}/${GAME_PLAN_FEEDBACK_ROUTE}`;
export const GAME_PLAN_FEEDBACK_COMPLETE = `${GAME_PLAN}/${GAME_PLAN_FEEDBACK_COMPLETE_ROUTE}`;
export const GAME_PLAN_BREAKDOWN = `${GAME_PLAN}/${GAME_PLAN_BREAKDOWN_ROUTE}`;

/**
 * PURCHASE
 */
export const SEARCH_TRACKER = `/search-tracker`;
export const SEARCH_TRACKER_HOME_ROUTE = `${SEARCH_TRACKER}/*`;

export const PURCHASE = `/purchase/:purchaseId/*`;

export const PURCHASE_THE_NUMBERS = `the-numbers`;
export const PURCHASE_REVIEW_THE_BRIEF = `review-the-brief`;
export const PURCHASE_PRE_APPROVAL = `pre-approval`;
export const PURCHASE_ID_VERIFICATION = `provide-id`;
export const PURCHASE_PURCHASE_AGREEMENT = `purchase-agreement`;
export const PURCHASE_PAYMENT = `payment`;
export const PURCHASE_YOUR_MARKET = `your-market`;
export const PURCHASE_YOUR_SUBURBS = `your-suburbs`;
export const PURCHASE_BRIEF_REQUIREMENTS = `brief-requirements`;
export const PURCHASE_SEARCH_TRACKER = `search-tracker`;
export const PURCHASE_PROPERTY = `property/:propertyId`;

export const PURCHASE_PROPERTY_ROUTE = `/purchase/:purchaseId/property/:shortlistPropertyId/*`;

export const PURCHASE_PROPERTY_PHOTOS = `photos`;
export const PURCHASE_PROPERTY_INITIAL_APPRAISAL = `initial-appraisal`;
export const PURCHASE_PROPERTY_SWOT_ANALYSIS = `swot-analysis`;
export const PURCHASE_PROPERTY_CHANCE_OF_BUYING = `chance-of-buying`;
export const PURCHASE_PROPERTY_GENERAL_TERMS = `general-terms`;
export const PURCHASE_PROPERTY_OUR_THOUGHTS = `our-thoughts`;
export const PURCHASE_PROPERTY_LOCAL_AMENITIES = `local-amenities`;
export const PURCHASE_PROPERTY_SCHOOLS_RESEARCH = `schools-research`;
export const PURCHASE_PROPERTY_DEVELOPMENT_APPLICATIONS = `development-applications`;
export const PURCHASE_PROPERTY_RISK_CONSIDERATIONS = `risk-considerations`;
export const PURCHASE_PROPERTY_NEIGHBOURHOOD = `neighbours`;
export const PURCHASE_PROPERTY_CONSTRUCTION = `construction`;
export const PURCHASE_PROPERTY_COMPARABLE_SALES = `comparable-sales`;
export const PURCHASE_PROPERTY_PURCHASE_STRATEGY = `purchase-strategy`;
export const PURCHASE_PROPERTY_FINAL_APPRAISAL = `final-appraisal`;
export const PURCHASE_PROPERTY_PURCHASE_TERMS = `purchase-terms`;
export const PURCHASE_PROPERTY_PRE_PURCHASE_REPORTS = `pre-purchase-reports`;
export const PURCHASE_PROPERTY_CONTRACT_OF_SALE = `contract-of-sale`;
export const PURCHASE_PROPERTY_AUTHORITY_TO_BID = `authority-to-bid`;
export const PURCHASE_PROPERTY_NEGOTIATIONS = `negotiations`;
export const PURCHASE_PROPERTY_AUCTION_ADVICE = `auction-advice`;
export const PURCHASE_PROPERTY_AUCTION_DAY = `auction-day`;
export const PURCHASE_PROPERTY_FINAL_OUTCOME = `final-outcome`;
export const PURCHASE_PROPERTY_SIGN_CONTRACT = `sign-contract`;
export const PURCHASE_PROPERTY_PAY_DEPOSIT = `pay-deposit`;
export const PURCHASE_PROPERTY_UNCONDITIONAL_EXCHANGE = `unconditional-exchange`;
export const PURCHASE_PROPERTY_PAY_INSURANCE = `pay-insurance`;
export const PURCHASE_PROPERTY_FINANCE_APPROVAL = `finance-approval`;
export const PURCHASE_PROPERTY_POST_EXCHANGE = `post-exchange`;
export const PURCHASE_PROPERTY_SETTLEMENT = `settlement`;
export const PURCHASE_PROPERTY_PRE_SETTLEMENT_INSPECTION = `pre-settlement-inspection`;
export const PURCHASE_PROPERTY_CONDITIONAL_EXCHANGE = `conditional-exchange`;
/**
 * SUBURB DETAILS
 */
export const SUBURB = `/suburb/:suburbId`;

/**
 * ACTIVE PORTFOLIO
 */
export const ACTIVE_PORTFOLIO = `/own`;
export const ACTIVE_PORTFOLIO_ROUTE = `${ACTIVE_PORTFOLIO}/*`;
export const ACTIVE_PORTFOLIO_ACTIVATION = `activation`;
export const ACTIVE_PORTFOLIO_DASHBOARD = `portfolio`;
export const ACTIVE_PORTFOLIO_DASHBOARD_ROUTE = `${ACTIVE_PORTFOLIO_DASHBOARD}/*`;
export const ACTIVE_PORTFOLIO_PROPERTY = `property/:propertyId/*`;
export const ACTIVE_PORTFOLIO_MY_PROPERTIES = `my-properties`;
export const ACTIVE_PORTFOLIO_MY_PROPERTIES_ROUTE = `${ACTIVE_PORTFOLIO}/${ACTIVE_PORTFOLIO_MY_PROPERTIES}`;
export const ACTIVE_PORTFOLIO_PROPERTY_ROUTE = `${ACTIVE_PORTFOLIO}/${ACTIVE_PORTFOLIO_PROPERTY}`;

/**
 * ACTIVE PORTFOLIO DASHBOARD
 */

export const PERFORMANCE = `performance`;
export const CASH_FLOW = `cash-flow`;
export const OPTIONS = `options`;
export const ACTIVE_PORTFOLIO_PERFORMANCE = `${ACTIVE_PORTFOLIO}/${ACTIVE_PORTFOLIO_DASHBOARD}/${PERFORMANCE}`;
export const ACTIVE_PORTFOLIO_CASHFLOW = `${ACTIVE_PORTFOLIO}/${ACTIVE_PORTFOLIO_DASHBOARD}/${CASH_FLOW}`;
export const ACTIVE_PORTFOLIO_GAME_PLAN = `${ACTIVE_PORTFOLIO}/${ACTIVE_PORTFOLIO_DASHBOARD}${GAME_PLAN}`;
export const ACTIVE_PORTFOLIO_OPTIONS = `${ACTIVE_PORTFOLIO}/${ACTIVE_PORTFOLIO_DASHBOARD}/${OPTIONS}`;

/**
 * ACTIVE PORTFOLIO ACTIVE PROPERTY
 */

export const DETAILS = `details`;
export const LEDGER = `ledger`;
export const DOCUMENTS = `documents`;
export const MAINTENANCE = `maintenance`;
export const TENANTS = `tenants`;
export const INVENTORY = `inventory`;

/**
 * Refer a friend
 */

export const REFER_A_FRIEND = `/refer-a-friend`;
export const REFER_A_FRIEND_ROUTE = `${REFER_A_FRIEND}/*`;
export const REFER_A_FRIEND_THANKS = `thanks`;
export const REFER_A_FRIEND_THANKS_ROUTE = `${REFER_A_FRIEND}/${REFER_A_FRIEND_THANKS}`;
