import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "routing/private-route";
import {
  ACTIVE_PORTFOLIO_ACTIVATION,
  ACTIVE_PORTFOLIO_DASHBOARD_ROUTE,
  ACTIVE_PORTFOLIO_MY_PROPERTIES,
  ACTIVE_PORTFOLIO_PROPERTY,
} from "routing/routes";
const Activation = lazy(() => import("./activation/activation"));
const Dashboard = lazy(() => import("./dashboard/dashboard"));
const Property = lazy(() => import("./property/property"));
const MyProperties = lazy(() => import("./my-properties/my-properties"));

const ActivePortfolioRoutes = () => {
  return (
    <Routes>
      <Route path={ACTIVE_PORTFOLIO_ACTIVATION} element={<Activation />} />
      <Route path={ACTIVE_PORTFOLIO_DASHBOARD_ROUTE} element={<PrivateRoute element={<Dashboard />} />} />
      <Route path={ACTIVE_PORTFOLIO_PROPERTY} element={<PrivateRoute element={<Property />} />} />
      <Route path={ACTIVE_PORTFOLIO_MY_PROPERTIES} element={<PrivateRoute element={<MyProperties />} />} />
    </Routes>
  );
};

export default ActivePortfolioRoutes;
