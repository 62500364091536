import styled, { createGlobalStyle } from "styled-components";
import { rem } from "../core/styles";
import { H3, Body } from "../core/typography.styles";
import { ButtonLink } from "../buttons";
import { white } from "../core/colours.styles";

export const ModalStyles = createGlobalStyle`
  .ReactModal__Overlay {
    background-color: rgba(0,0,0,0.5) !important;
    opacity: 0;
    transition: opacity 0.2s;
    max-height: 100%;
    overflow-y: auto;
    z-index: 10000;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModal__Body--open {
    overflow: hidden;
  }

  .ReactModal__Overlay--after-open {
    display: flex;
    justify-content: center;
    padding-top: 60px;
    opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }

  .ReactModal__Content.ReactModal__Content--after-open {
    @media screen and (max-width: 600px) {
      border-radius: 0 !important;
      top: 0 !important;
      left: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
      max-width: 100% !important;
    }
  }
`;

export const ModalContent = styled.div`
  padding: ${rem(64)} ${rem(32)} ${rem(80)} ${rem(32)};
`;

export const ConfirmationModalContent = styled.div`
  padding: ${rem(40)} ${rem(32)} ${rem(40)} ${rem(32)};
`;

export const ModalClose = styled.div`
  position: absolute;
  right: ${rem(16)};
  top: ${rem(16)};
  z-index: 10;
`;

export const ModalCenteredContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  max-width: ${rem(500)};
  margin: auto;

  ${H3} {
    margin-bottom: ${rem(8)};
  }
`;

export const ModalActions = styled.div`
  box-shadow: 0px -4px 5px 0px rgb(0 0 0 / 10%);
  display: flex;
  align-items: center;
  padding: 0 ${rem(32)};
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: ${rem(80)};
  @media screen and (max-width: 600px) {
    position: fixed;
    background-color: ${white};
  }

  button {
    margin-right: ${rem(24)};

    &:last-child {
      margin-right: 0;
    }
  }

  > * {
    width: 100%;
  }
`;

export const ModalStackedActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${rem(24)};
  ${ButtonLink} {
    margin-top: ${rem(24)};
  }
`;

export const ModalConfirmContent = styled.div`
  height: 100%;
  padding: ${rem(64)} ${rem(32)} ${rem(40)} ${rem(32)};
`;

export const ModalConfirmTitle = styled(H3)`
  margin-bottom: ${rem(8)};
  text-align: center;
`;

export const ModalConfirmText = styled(Body)`
  text-align: center;
`;
