import styled from "styled-components";
import { SpacingValue } from "../core/spacing.styles";
import { rem } from "../core/styles";

type StackProps = {
  spacing?: SpacingValue;
  direction?: "vertical" | "horizontal";
  marginBottom?: SpacingValue;
  wrap?: boolean;
};

export const Stack = styled.div<StackProps>`
  ${({ direction = "vertical" }) =>
    direction === "horizontal" &&
    `
      display: flex;
      align-items: center;
    `}

  > *:not(:last-child) {
    ${({ direction = "vertical", spacing = 64 }) =>
      direction === "vertical"
        ? `
      margin-bottom: ${rem(spacing)};
      `
        : `
      margin-right: ${rem(spacing)};
      `}
  }

  ${({ wrap }) =>
    wrap &&
    `
    flex-wrap: wrap;
  `}

  margin: 0 0 ${({ marginBottom = 0 }) => rem(marginBottom)} 0;
`;
