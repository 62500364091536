import { createContext, ReactNode, useState, useCallback, useMemo, useRef, useContext } from "react";

export enum NotificationMode {
  Normal,
  Warning,
  Error,
}

export enum NotificationPosition {
  Top,
  Bottom,
}

type NotificationContextData = {
  displayNotification?: boolean;
  text?: ReactNode;
  mode: NotificationMode;
  position?: NotificationPosition;
  hideable: boolean;
  showNotification: (
    text: ReactNode,
    options?: {
      mode?: NotificationMode;
      position?: NotificationPosition;
      autoHide?: boolean;
    }
  ) => void;
  showSuccess: (text: ReactNode, options?: { position?: NotificationPosition }) => void;
  showError: (text: ReactNode, options?: { position?: NotificationPosition; autoHide?: boolean }) => void;
  hideNotification: () => void;
};

export const NotificationContext = createContext<NotificationContextData>({
  displayNotification: false,
  hideable: false,
  showNotification: () => undefined,
  showSuccess: () => undefined,
  showError: () => undefined,
  hideNotification: () => undefined,
  mode: NotificationMode.Normal,
  position: NotificationPosition.Top,
});

export const NotificationContextProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [displayNotification, setDisplayNotification] = useState<boolean | undefined>();
  const [text, setText] = useState<ReactNode>();
  const [mode, setMode] = useState<NotificationMode>(NotificationMode.Error);
  const [position, setPosition] = useState<NotificationPosition>(NotificationPosition.Top);
  const [hideable, setHideable] = useState<boolean>(false);
  const slideDownTimeout = useRef<number | undefined | null>(null);
  const displayNoneTimeout = useRef<number | undefined | null>(null);

  const handleDisplayNotification = useCallback(
    (
      text: ReactNode,
      options?: {
        mode?: NotificationMode;
        position?: NotificationPosition;
        autoHide?: boolean;
      }
    ) => {
      if (slideDownTimeout && slideDownTimeout.current) clearTimeout(slideDownTimeout.current);
      if (displayNoneTimeout && displayNoneTimeout.current) clearTimeout(displayNoneTimeout.current);

      setText(text);
      setMode(options?.mode ?? NotificationMode.Error);
      setPosition(options?.position ?? NotificationPosition.Top);
      setHideable(!options?.autoHide);
      setDisplayNotification(true);

      if (options?.autoHide === true) {
        slideDownTimeout.current = window.setTimeout(() => {
          // activate the slide down animation
          setDisplayNotification(false);
          displayNoneTimeout.current = window.setTimeout(() => {
            // hide the notification element
            setDisplayNotification(undefined);
          }, 1000);
        }, 4000);
      }
    },
    [slideDownTimeout, displayNoneTimeout]
  );

  const handleCloseNotification = useCallback(() => {
    setDisplayNotification(false);
  }, []);

  const handleShowSuccess = useCallback(
    (text: ReactNode, options?: { position?: NotificationPosition }) => {
      handleDisplayNotification(text, {
        ...options,
        mode: NotificationMode.Normal,
        autoHide: true,
      });
    },
    [handleDisplayNotification]
  );

  const handleShowError = useCallback(
    (text: ReactNode, options?: { position?: NotificationPosition; autoHide?: boolean }) => {
      handleDisplayNotification(text, {
        ...options,
        mode: NotificationMode.Error,
      });
    },
    [handleDisplayNotification]
  );

  const value = useMemo(
    () => ({
      text,
      mode,
      position,
      hideable,
      displayNotification,
      showNotification: handleDisplayNotification,
      showSuccess: handleShowSuccess,
      showError: handleShowError,
      hideNotification: handleCloseNotification,
    }),
    [displayNotification, handleDisplayNotification, text, mode, position]
  );

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};

export const useNotification = (): NotificationContextData => useContext(NotificationContext);
