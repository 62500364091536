import { useMutation } from "@apollo/client";
import { AskAQuestionInput, AskAQuestionMutation, AskAQuestionMutationVariables } from "@mc/common/gql/types";
import { PrimaryLoadingButton } from "@mc/components/buttons";
import { Margin } from "@mc/components/core/spacing.styles";
import { H2 } from "@mc/components/core/typography.styles";
import { Flex } from "@mc/components/grid/grid.styles";
import { ErrorMessage } from "@mc/components/inputs/errors.styles";
import { TextAreaInput } from "@mc/components/inputs/inputs.styles";
import { Modal } from "@mc/components/modal/modal";
import { useNotification } from "@mc/components/notification/notification.context";
import { useForm } from "react-hook-form";
import { ASK_A_QUESTION } from "./ask-a-question-modal.gql";

type Props = {
  close: () => void;
};

type FormData = AskAQuestionInput;

const AskAQuestionModal = ({ close }: Props) => {
  const { handleSubmit, register, formState } = useForm<FormData>();
  const { errors } = formState;

  const { showSuccess } = useNotification();

  const [askAQuestion, { loading }] = useMutation<AskAQuestionMutation, AskAQuestionMutationVariables>(ASK_A_QUESTION, {
    onCompleted: () => {
      showSuccess("Question submitted");
      close();
    },
  });

  const onSubmit = handleSubmit((formData: FormData) => {
    askAQuestion({
      variables: {
        askAQuestionInput: formData,
      },
    });
  });

  return (
    <Modal isVisible close={close}>
      <H2>Ask a question</H2>

      <form onSubmit={onSubmit}>
        <div>
          <div>
            <TextAreaInput
              {...register("question", { required: "Please enter a question." })}
              placeholder="e.g. How can I decrease my money out next quarter?"
            />
            <ErrorMessage error={errors.question?.message} />
          </div>

          <Margin top={24}>
            <Flex justifyContent="center">
              <PrimaryLoadingButton isLoading={loading} type="submit">
                Submit
              </PrimaryLoadingButton>
            </Flex>
          </Margin>
        </div>
      </form>
    </Modal>
  );
};

export default AskAQuestionModal;
