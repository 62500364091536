import styled from "styled-components";
import { rem } from "@mc/components/core/styles";
import { black, highlight, lightGrey, pureBlack, tan } from "@mc/components/core/colours.styles";
import { fontSize14, fontWeightNormal, fontWeightSemiBold } from "@mc/components/core/typography.styles";

type NavigationContainerProps = {
  isOpen: boolean;
  $withBorder?: boolean;
};

export const Overlay = styled.div<NavigationContainerProps>`
  position: fixed;
  left: 0;
  width: 100vw;
  height: 100vh;
  top: 0;
  background: ${pureBlack};
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  z-index: 999;
`;

export const MenuButton = styled.button``;

export const Navigation = styled.div``;

export const MenuContainer = styled.div<NavigationContainerProps>`
  ${Navigation} {
    position: fixed;
    top: 0;
    left: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
    width: ${rem(300)};
    height: 100vh;
    background-color: ${tan};
    padding: ${rem(16)} ${rem(16)} 0;
    transition: left 0.3s ease-in-out;
    overflow-x: hidden;
    z-index: 1000;

    @media (max-width: ${rem(768)}) {
      width: 80vw;
    }
    overflow-y: auto;
  }
  ${MenuButton} {
    border: ${({ $withBorder = true }) => ($withBorder ? `1px solid ${lightGrey}` : `none`)};
  }
`;

export const MenuList = styled.ul`
  list-style: none;
  margin: ${rem(20)} 0 0 0;
  padding: 0;
`;

export const MenuItem = styled.li<{ isDisabled?: boolean }>`
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: ${rem(8)};
  position: relative;
  opacity: ${({ isDisabled = false }) => (isDisabled ? 0.5 : 1)};
  pointer-events: ${({ isDisabled = false }) => (isDisabled ? "none" : "auto")};
`;

export const MenuItemHeader = styled.div<{ hasSubmenu?: boolean }>`
  padding: ${rem(12)} ${rem(16)};
  border-radius: ${rem(150)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: ${rem(8)} ${rem(24)} ${rem(8)} ${rem(12)};
  &:hover {
    cursor: pointer;
    background-color: ${({ hasSubmenu }) => (hasSubmenu ? "inherit" : highlight)};
  }
`;

export const MenuLabel = styled.span`
  color: ${black};
  font-size: ${fontSize14};
  font-weight: ${fontWeightSemiBold};
  margin-left: ${rem(16)};
  flex-grow: 1;
`;

export const MenuLink = styled.a<{ $isSubmenu?: boolean }>`
  color: ${black};
  font-size: ${fontSize14};
  font-weight: ${({ $isSubmenu }) => ($isSubmenu ? fontWeightNormal : fontWeightSemiBold)};
  margin-left: ${rem(16)};
  text-decoration: "none";
  flex-grow: 1;
  &:hover {
    cursor: pointer;
    color: ${({ $isSubmenu }) => ($isSubmenu ? highlight : black)};
  }
`;

export const ArrowIcon = styled.span`
  display: flex;
  align-items: center;
  padding-left: ${rem(8)};
`;

export const SubMenu = styled.ul<{ isOpen: boolean }>`
  list-style: none;
  margin: 0;
  right: 0;
  padding: ${rem(8)} 0;
  background-color: ${tan};
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  padding-left: 0;
`;

export const SubMenuItem = styled.li`
  padding: ${rem(8)} ${rem(16)};
  white-space: nowrap;
`;
