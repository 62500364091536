import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import { baseBoxShadowFocus, marginCss, MarginProps, rem, standardFocusState } from "./styles";
import { black, lightGrey, darkGrey } from "./colours.styles";
import { SpacingValue } from "./spacing.styles";

export const fontFamilyBase = `'DM Sans', sans-serif`;
export const fontFamilyHeading = `Poppins, serif`;

export const fontWeightNormal = 400;
export const fontWeightRegular = 400;
export const fontWeightMedium = 500;
export const fontWeightSemiBold = 600;
export const fontWeightBold = 700;

type Alignments = "left" | "center" | "right";

export const weights = {
  normal: fontWeightNormal,
  regular: fontWeightRegular,
  medium: fontWeightMedium,
  semiBold: fontWeightSemiBold,
  bold: fontWeightBold,
};

export const fontSize11 = rem(11);
export const fontSize12 = rem(12);
export const fontSize13 = rem(13);
export const fontSize14 = rem(14);
export const fontSize16 = rem(16);
export const fontSize18 = rem(18);
export const fontSize20 = rem(20);
export const fontSize24 = rem(24);
export const fontSize28 = rem(28);
export const fontSize34 = rem(34);

export const lineHeight15 = rem(15);
export const lineHeight16 = rem(16);
export const lineHeight22 = rem(22);
export const lineHeight24 = rem(24);
export const lineHeight26 = rem(26);
export const lineHeight30 = rem(30);
export const lineHeight32 = rem(32);
export const lineHeight40 = rem(40);

type HeadingProps = {
  marginBottom?: SpacingValue;
  textAlign?: Alignments;
  colour?: string;
};

const baseHeading = css<HeadingProps>`
  font-family: ${fontFamilyHeading};
  font-weight: ${fontWeightSemiBold};
  margin: 0 0 ${({ marginBottom }) => (marginBottom !== undefined ? rem(marginBottom) : rem(24))} 0;
  ${({ textAlign }) =>
    textAlign &&
    `
    display: block;
    text-align: ${textAlign};
    width: 100%;
  `}
  ${({ colour = black }) => `color: ${colour};`}
`;

export const H1 = styled.h1<HeadingProps>`
  ${baseHeading}
  font-size: ${fontSize34};
  line-height: ${lineHeight40};
`;

export const H2 = styled.h2<HeadingProps>`
  ${baseHeading}
  font-size: ${fontSize28};
  line-height: ${lineHeight32};
`;

export const H3 = styled.h3<HeadingProps>`
  ${baseHeading}
  font-size: ${fontSize24};
  line-height: ${lineHeight30};
`;

export const H4 = styled.h4<HeadingProps>`
  ${baseHeading}
  font-size: ${fontSize20};
  line-height: ${lineHeight24};
`;

export const H5 = styled.h5<HeadingProps>`
  ${baseHeading}
  font-size: ${fontSize18};
  line-height: ${lineHeight22};
`;

type BodyProps = {
  size?: "small" | "medium" | "large";
  marginBottom?: SpacingValue;
  textAlign?: Alignments;
  colour?: string;
  textDecoration?: string;
};

export const Body = styled.p<BodyProps>`
  font-family: ${fontFamilyBase};
  font-weight: ${fontWeightNormal};
  font-size: ${({ size = "medium" }) => {
    switch (size) {
      case "small":
        return fontSize13;
      case "large":
        return fontSize18;
      default:
        return fontSize16;
    }
  }};
  line-height: ${({ size = "medium" }) => {
    switch (size) {
      case "small":
        return lineHeight16;
      case "large":
        return lineHeight26;
      default:
        return lineHeight22;
    }
  }};
  margin: 0 0 ${({ marginBottom = 24 }) => rem(marginBottom)} 0;
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
  ${({ colour }) => colour && `color: ${colour};`}
  ${({ textDecoration = "none" }) => textDecoration && `text-decoration: ${textDecoration};`}
`;

export const ListStyled = styled.ol`
  font-size: ${fontSize16};
  line-height: ${lineHeight22};
  margin: 0 0 ${rem(16)} 0;
  padding: 0 0 0 ${rem(16)};

  li {
    line-height: ${lineHeight22};
    margin: 0 0 ${rem(20)} 0;
  }
`;

type AnchorProps = {
  $fontWeight?: keyof typeof weights;
} & MarginProps;

export const anchorLinkBase = css<AnchorProps>`
  border-radius: ${rem(4)};
  color: ${black};
  font-weight: ${({ $fontWeight = "regular" }) => weights[$fontWeight]};
  font-size: ${fontSize16};
  line-height: ${lineHeight22};
  text-decoration: underline;

  &:hover {
    color: ${black};
    cursor: pointer;
    text-decoration: underline;
  }

  ${standardFocusState};

  &:focus {
    box-shadow: ${baseBoxShadowFocus};
    color: ${black};
  }

  &.disabled,
  &.disabled:hover {
    color: ${lightGrey};
    text-decoration: none;
  }

  ${() => marginCss()}
`;

export const AnchorStyled = styled(Link)`
  ${anchorLinkBase};
`;

export const AnchorOriginalStyled = styled.a`
  ${anchorLinkBase};
`;

export const Label = styled.div<{ marginBottom?: SpacingValue; isSmall?: boolean }>`
  font-size: ${({ isSmall = false }) => (isSmall ? fontSize11 : fontSize12)};
  line-height: ${({ isSmall = false }) => (isSmall ? lineHeight15 : lineHeight16)};
  letter-spacing: 0.5px;
  margin: 0 0 ${({ marginBottom = 8 }) => rem(marginBottom)} 0;
  text-transform: uppercase;
`;

export const PlaceholderStyled = styled(Body)`
  color: ${darkGrey};
`;
